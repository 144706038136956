.ant-layout {
    // font-family: 'Jost', sans-serif;
    font-family: "Roboto", sans-serif;
    .ant-layout-content {
        overflow-y: auto;
    }
    .ant-layout-sider {
        transition: all .4s ease 0s;
        background: #fff;
        .ant-layout-sider-children {
            .demo-logo-vertical {
                min-height: 66px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 3px solid #f5f5f5;
                border-right: 1px solid #3e75ff;
                background: #ffffff;
                font-family: "Roboto", sans-serif;
                font-size: 2.15rem;
                color: #Fff;
                font-weight: 400;
                figure {
                    margin: 0;
                    img {
                        width: 157px;
                        transition: all 1s ease 0s;
                        transition-duration: 1s;
                        display: none;
                    }
                    .responsive-logo {
                        display: block;
                        width: 50px;
                    }
                }

            }
            .ant-menu {
                background: #fff;
                display: flex;
                flex-direction: column;
                gap: 15px;
                .ant-menu-item {
                    color: #000;
                    font-family: "Roboto", sans-serif;
                    font-size: 1rem;
                    font-weight: 400;
                    &.ant-menu-item-selected {
                        background-color: transparent;
                        color: #0047FF;
                    }
                    &:hover {
                        background-color: #f0f0f0;
                    }
                }
            }
        }
        &.ant-layout-sider-collapsed {
            transition: all .4s ease 0s;
            .ant-layout-sider-children {
                .demo-logo-vertical {
                    figure {
                        img {
                            display: none;
                            transition: all 1s ease 0s;
                        }
                        .responsive-logo {
                            display: block;
                            width: 50px;
                            transition: all 1s ease 0s;
                        }
                    }
                }
            }
        }
    }
    .ant-layout-header {
        background: #0047FF;
        display: flex;
        justify-content: space-between;
        .ant-btn-icon {
            .anticon-menu-unfold {
                font-size: 25px;
                color: #fff;       
            }
            .anticon-menu-fold {
                font-size: 25px;
                color: #fff;
            }
        }
        .user-info {
            display: flex;
            gap: 10px;
            padding-right: 30px;
            .user-name {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                padding-right: 10px;
                p {
                    font-size: 17px;
                    font-weight: 500;
                    margin-bottom: 5px;
                    text-align: right;
                    line-height: 1;
                    color: #fff;
                }
            }
            figure {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                img {
                    width: 100%;
                    object-fit: cover;
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                }
            }
        }
    }
}